<template>
  <div>
    <!--Overlay Effect-->
    <div class="fixed inset-0 bg-black bg-opacity-80 overflow-y-auto h-full w-full z-50 block" id="modal-contact">
      <div class="w-full h-full absolute" @click="close()"></div>
      <!--modal content-->
      <div class="relative top-1/2 transform -translate-y-1/2 mx-auto max-w-sm bg-white rounded-2xl">
        <div class="px-6 py-4 text-sm">
          <div class="items-center">
            <span class="text-primary font-bold">Recipient TripwePay Number</span>
            <div class="flex items-center mt-1">
              <input type="text" class="py-1.5 px-3 w-full border border-51 text-51 rounded-lg font-medium"
                v-model="searchContact">
              <span class="p-1.5 bg-primary text-white rounded-lg font-semibold ml-3">OK</span>
            </div>
          </div>
          <div class="items-center mt-3" v-if="!loadingRecent">
            <span class="text-primary font-bold">Saved List</span>
            <div class="flex items-center py-1 border-51 text-base" v-for="(item, index) in resultContact"
              :key="index" :class="index != contacts.length - 1 ? 'border-b' : ''">
              <span
                class="flex items-center justify-center cursor-pointer bg-red-1 text-xs text-white w-7 h-7 rounded-full"
                @click="deleteOpen(item)">
                <font-awesome-icon icon="fa-solid fa-trash" />
              </span>
              <div class="ml-3 flex-col flex leading-none cursor-pointer" @click="input(item)">
                <span class="font-bold uppercase">{{ item.nama }}</span>
                <span class="font-medium">{{ item.telepon }}</span>
              </div>
            </div>
          </div>
          <div class="items-center mt-3" v-else>
            <span class="text-primary font-bold">Saved List</span>
            <div class="flex items-center py-1 border-51 text-base">
              <span
                class="flex items-center justify-center bg-gray-200 cursor-pointer transition-all duration-300 animate-pulse my-0.5 text-xs w-7 h-7 rounded-full">
              </span>
              <div class="ml-3 flex-col flex leading-none">
                <span
                  class="rounded-10 h-4 bg-gray-200 cursor-pointer w-40 transition-all duration-300 animate-pulse my-0.5"></span>
                <span
                  class="rounded-10 h-4 bg-gray-200 cursor-pointer w-24 transition-all duration-300 animate-pulse my-0.5"></span>
              </div>
            </div>
            <div class="flex items-center py-1 border-51 text-base">
              <span
                class="flex items-center justify-center bg-gray-200 cursor-pointer transition-all duration-300 animate-pulse my-0.5 text-xs w-7 h-7 rounded-full">
              </span>
              <div class="ml-3 flex-col flex leading-none">
                <span
                  class="rounded-10 h-4 bg-gray-200 cursor-pointer w-40 transition-all duration-300 animate-pulse my-0.5"></span>
                <span
                  class="rounded-10 h-4 bg-gray-200 cursor-pointer w-24 transition-all duration-300 animate-pulse my-0.5"></span>
              </div>
            </div>
            <div class="flex items-center py-1 border-51 text-base">
              <span
                class="flex items-center justify-center bg-gray-200 cursor-pointer transition-all duration-300 animate-pulse my-0.5 text-xs w-7 h-7 rounded-full">
              </span>
              <div class="ml-3 flex-col flex leading-none">
                <span
                  class="rounded-10 h-4 bg-gray-200 cursor-pointer w-40 transition-all duration-300 animate-pulse my-0.5"></span>
                <span
                  class="rounded-10 h-4 bg-gray-200 cursor-pointer w-24 transition-all duration-300 animate-pulse my-0.5"></span>
              </div>
            </div>
            <div class="flex items-center py-1 border-51 text-base">
              <span
                class="flex items-center justify-center bg-gray-200 cursor-pointer transition-all duration-300 animate-pulse my-0.5 text-xs w-7 h-7 rounded-full">
              </span>
              <div class="ml-3 flex-col flex leading-none">
                <span
                  class="rounded-10 h-4 bg-gray-200 cursor-pointer w-40 transition-all duration-300 animate-pulse my-0.5"></span>
                <span
                  class="rounded-10 h-4 bg-gray-200 cursor-pointer w-24 transition-all duration-300 animate-pulse my-0.5"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Content End -->
    <!--Overlay Effect-->
    <div v-if="deleteDialog" class="fixed inset-0 bg-black bg-opacity-80 overflow-y-auto h-full w-full z-999 block"
      id="modal-contact">
      <div class="w-full h-full absolute" @click="deleteClose"></div>
      <!--modal content-->
      <div class="relative top-1/2 transform -translate-y-1/2 mx-auto max-w-sm bg-white rounded-2xl">
        <div class="px-6 py-4 text-sm">
          <p class="font-medium">Are you sure you want to delete this data?</p>
          <div class="flex justify-end space-x-3 mt-3">
            <button class="px-4 py-1 text-primary rounded-lg font-medium hover:bg-primary hover:text-white"
              @click="deleteClose">No</button>
            <button class="px-4 py-1 text-primary rounded-lg font-medium hover:bg-primary hover:text-white"
              @click="deleteData">Yes</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Content End -->
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    type:{
      type: String,
      required: true,
    },
    bankCode:{
      type: String,
      required: false,
    },
  },
  components: {
  },
  data() {
    return {
      searchContact: null,
      loadingRecent: false,
      deleteDialog: false,
      deleteItem: null,
      contacts: []
    }
  },
  methods: {
    close() {
      this.$emit('closeContact')
    },
    input(val) {
      if(this.type == "bank"){
        this.$emit('addInput', val.rekening);
      }else{
        this.$emit('addInput', val.telepon);
      }
      this.close()
    },
    async getContact() {
      this.loadingRecent = true
      try {
        var fData = new FormData();
        fData.append("txtId", this.$store.state.user.id_user);
        let res;
        if(this.type == "bank"){
          fData.append("txtKode", this.bankCode);
          res = await this.$http.post(`${this.$apiTripwe}/ic/jetski-android-new/list_daftar_transfer_bank_v16.php`, fData)
        }else{
          res = await this.$http.post(`${this.$apiTripwe}/ic/jetski-android-new/list_daftar_transfer_pengguna_v16.php`, fData)
        }
        this.contacts = res.data
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.loadingRecent = false;
      }
    },
    deleteClose() {
      this.deleteDialog = false
    },
    deleteOpen(val) {
      this.deleteDialog = true
        if(this.type == "bank"){
          this.deleteItem = val.id_save_bank;
        }else{
          this.deleteItem = val.id_save_pengguna;
        }
    },
    async deleteData() {
      this.loadingRecent = true
      try {
        var fData = new FormData();
        fData.append("txtId", this.deleteItem);
        fData.append("mobile", "android");
        if(this.type == "bank"){
          await this.$http.post(`${this.$apiTripwe}/ic/jetski-android-new/delete_daftar_transfer_bank_v16.php`, fData)
        }else{
          await this.$http.post(`${this.$apiTripwe}/ic/jetski-android-new/delete_daftar_transfer_pengguna_v16.php`, fData)
        }
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.deleteDialog = false
        this.getContact()
      }
    }
  },
  computed: {
    resultContact() {
      if (this.searchContact) {
        return this.contacts.filter((item) => {
          return this.searchContact.toLowerCase().split(' ').every(v => item.nama.toLowerCase().includes(v)) || this.searchContact.toLowerCase().split(' ').every(v => item.telepon.toLowerCase().includes(v))
        })
      } else {
        return this.contacts
      }
    }
  },
  created() {
    this.getContact()
  }
}
</script>