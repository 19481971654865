<template>
  <fragment>
    <div class="flex flex-col items-center justify-center">
      <div class="w-full md:w-1/2 xl:w-2/5 space-y-4">
        <div id="group">
          <div class="text-sm font-semibold text-black whitespace-nowrap">
            Bank
          </div>
          <div class="flex justify-between h-9">
            <div
              class="bg-primary rounded-l-lg flex w-11 items-center justify-center text-center cursor-pointer"
            >
              <img :src="icBank" class="w-4" alt="lihat" />
            </div>
            <select id="select-bank" class="w-full outline-none px-5 text-xl font-semibold text-black border-b-4 border-primary" v-model="toBank.bank" @change="getTrxFee">
                <option value=""></option>
                <option :value="bank.code" v-for="(bank, bankidx) in listBank" :key="bankidx" >{{bank.name}}</option>
            </select>
          </div>
        </div>
        <div id="group">
          <div class="text-sm font-semibold text-black whitespace-nowrap">
            Account number
          </div>
          <div class="flex justify-between h-9">
            <div
              class="bg-primary rounded-l-lg flex w-11 items-center justify-center text-center cursor-pointer"
            >
              <img :src="icUser" class="w-4" alt="lihat" />
            </div>
            <input
              autofocus
              type="number"
              v-model="toBank.rek"
              class="w-full outline-none px-5 text-xl font-semibold text-black border-b-4 border-primary"
            />
            <div
              class="bg-primary rounded-r-lg flex w-11 items-center justify-center text-center cursor-pointer"
            >
              <font-awesome-icon icon="fa-solid fa-address-book" class="text-white" @click="openContact" />
            </div>
          </div>
        </div>
        <div id="group">
          <div class="text-sm font-semibold text-black whitespace-nowrap">
            Amount
          </div>
          <div class="flex justify-between h-9">
            <div
              class="flex bg-primary rounded-l-lg w-9 px-4 text-base font-bold items-center justify-center text-center text-white"
            >
              {{ $store.state.currency.kode_domestik ?? "Rp" }}
            </div>
            <input
              type="number"
              v-model="toBank.amount"
              placeholder="0"
              class="w-full outline-none px-5 text-xl font-semibold text-black border-b-4 border-primary"
            />
          </div>
        </div>
        <div id="group">
          <div class="text-sm font-semibold text-black whitespace-nowrap">
            Note
            <span class="font-medium text-xs text-black2">(optional) </span>
          </div>
          <div class="flex justify-between h-9">
            <div
              class="bg-primary rounded-l-lg flex w-11 items-center justify-center text-center cursor-pointer"
            >
              <img :src="icNote" class="w-4" alt="lihat" />
            </div>
            <input
              type="text"
              v-model="toBank.note"
              class="w-full outline-none px-5 text-xl font-semibold text-black border-b-4 border-primary"
            />
          </div>
        </div>
        <div id="group" v-if="false">
          <div class="text-sm font-semibold text-black whitespace-nowrap">
            Transfer Fee
          </div>
          <div class="flex justify-between h-9">
            <div
              class="flex bg-primary rounded-l-lg w-9 px-4 text-base font-bold items-center justify-center text-center text-white"
            >
              Rp
            </div>
            <input
              type="number"
              v-model="toBank.fee"
              disabled
              class="w-full outline-none px-5 text-xl font-semibold rounded-tr-10 text-black border-b-4 border-primary"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end w-full mt-20">
      <div
        @click="inquiry"
        class="bg-primary rounded-10 text-base text-white font-semibold py-2 px-9 cursor-pointer transition ease-in-out duration-300 hover:shadow-lg"
      >
        Next
      </div>
    </div>
    <Contact v-if="showContact" type="bank" :bankCode="toBank.bank" ref="contact" @closeContact="closeContact" @addInput="addInput" />
  </fragment>
</template>

<script>
import { NumberFormat } from "@/utils";
import Vue from 'vue';
import Contact from "@/components/layout/Contact.vue";

export default {
  name: "ToBankInput",
  components: { Contact },
  data: () => ({
    icBank: require("@/assets/icons/ic-bank.svg"),
    icUser: require("@/assets/icons/ic-user.svg"),
    icArrow: require("@/assets/icons/ic-arrow-outline.svg"),
    icNote: require("@/assets/icons/ic-note.svg"),
    listBank: [],
    showContact: false,
    toBank: {
      tokenInquiry: '',
      tokenPayment: '',
      bank: '',
      rek: '',
      amount: '',
      note: '',
      fee: 0,
      dataInquiry: {},
    },
  }),
  methods: {
    openContact(){
      this.showContact = true;
    },
    closeContact(){
      this.showContact = false
    },
    addInput(val){
      this.toBank.rek = val
    },
    numberFormat: num => NumberFormat(num),
    async getListBank(){
      this.$store.commit('setOverlayLoading', true);
      try {
        const res = await this.$http.get(`${this.$apiTripweWeb}/cashless-to-bank/banks`);
        this.listBank = res.data.data;
      } catch (error) {
        this.$toasted.global.error("Fail to load list bank");
      } finally {
        this.$store.commit('setOverlayLoading', false);
      }
    },
    sortedBank(){
      const sorter = (a,b) => b.Bank_Name1 - a.Bank_Name1;
      return this.listBank.sort(sorter);
    },
    validasiInquiry(){
      if(this.toBank.bank == null || this.toBank.bank == ''){
        this.$toasted.global.error("Bank cannot be empty");
        return false;
      }
      if(this.toBank.rek == null || this.toBank.rek == ''){
        this.$toasted.global.error("Account number cannot be empty");
        return false;
      }
      if(this.nominalReal < 10000){
        this.$toasted.global.error("Minimum transaction is 10.000");
        return false;
      }
      return true;
    },
    async inquiry(){
      var valid = this.validasiInquiry();
      if(!valid){
        return;
      }
      this.$store.commit('setOverlayLoading', true);
      try {
        var fData = {
          "uuid":this.$uuidv4(),
          "bank_code": this.toBank.bank,
          "account_no": this.toBank.rek,
          "amount":this.nominalReal,
          "remark":this.toBank.note,
        };
        const res = await this.$http.post(`${this.$apiTripweWeb}/cashless-to-bank/inquiry`,fData);
        if(res.data?.status == '200'){
          this.toBank.dataInquiry = res.data.data;
          this.next();
        }else{
          this.$toasted.global.error("Account not found");
        }
      } catch (error) {
        this.$toasted.global.error("Failed, please try again");
      } finally {
        this.$store.commit('setOverlayLoading', false);
      }
    },
    getTrxFee() {
      this.toBank.fee = 3000;
      if(this.toBank.bank == '014'){
        this.toBank.fee = 3000;
      }
    },
    next() {
      this.$router.push({ 
        name: 'ToBankFinal', 
        params: { 
          user: this.toBank.rek, 
          data: this.toBank
        } 
      });
    },
    randomStr(min, max){
      return Math. floor(Math. random()*(max-min+1)+min);
    }
  },
  created() {
    this.getListBank();
    var now = this.$moment();
    this.toBank.tokenInquiry = "TWIQ" + now.format("YYYYMMDDHHmmss") + this.randomStr(1,9999);
    this.toBank.tokenPayment = "TWIP" + now.format("YYYYMMDDHHmmss") + this.randomStr(1,9999);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    nominalReal() {
      var val = Vue.filter('exchangeCurrency')(this.toBank.amount);
      return val;
    }
  },
};
</script>
